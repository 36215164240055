import styled from 'styled-components'
import tw from 'twin.macro'
import dynamic from 'next/dynamic'
import { BaseTitle } from '../../base'
const Select = dynamic(() => import('react-select'), {
  ssr: false
})

export const SubHeaderWrapper = styled.div`
  ${tw`mb-5 lg:mb-0`}
  margin: 2rem auto;
`

export const SortDropdownStyled = styled(Select)`
  ${tw`uppercase italic w-full flex-initial lg:w-1/5`}

  & > div {
    overflow: initial;
    ${tw`rounded-none border-blue`}
    & > div > div {
      overflow: initial !important;
    }
  }
`

export const SubHeaderStyled = styled(BaseTitle)<{
  separator?: boolean
  hasDropdown?: boolean
}>`
  ${tw`text-white font-youfitnessHeading mb-0 lg:flex-1 text-center py-4 px-4 md:px-8 uppercase font-extrabold lg:pl-56 leading-tiny`}
  ${props => !props.hasDropdown && tw`lg:px-10 `}
`

export const ContentWrapper = styled.div`
  ${tw`flex flex-col lg:flex-row items-center`}
`
