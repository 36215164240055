import {
  FirstContentBlock,
  LAYOUT_TEMPLATE,
  SelectOption,
  WagtailBlockProps
} from '../../../services/api/types'
import { SORT_OPTIONS, SORT_PLACEHODLER } from '../../../services/api/constants'
import * as S from './SubHeader.styled'
import { UnderlineCustom } from '../../../styles/global.styles'
import { Section, Container } from '../../../styles/grid.styles'
import { IconEnums, SVGIcon } from '../../base'

export interface SubHeaderProps extends FirstContentBlock {
  subHeaderText: string
  showDropdown: boolean
  showSeparator: boolean
  dropdownOptions?: Array<SelectOption>
  dropdownPlaceholder?: string
  onSortOptionChange?: Function
  template?: LAYOUT_TEMPLATE
}

export interface SubHeaderAPIProps extends WagtailBlockProps {
  value: {
    heading: string
  }
}

export const SubHeader = (props: SubHeaderProps) => {
  const {
    subHeaderText,
    dropdownPlaceholder,
    dropdownOptions,
    showDropdown,
    showSeparator,
    onSortOptionChange,
    isFirstBlock
  } = props

  const handleOptionChange = (event: SelectOption) => {
    onSortOptionChange && onSortOptionChange(event)
  }

  return (
    <Section pb='1.875rem'>
      <Container>
        <S.ContentWrapper>
          <S.SubHeaderWrapper>
            <S.SubHeaderStyled
              hasDropdown={showDropdown}
              separator={showSeparator}
              isSiteTitle={isFirstBlock}
            >
              {subHeaderText}
            </S.SubHeaderStyled>
            <UnderlineCustom
              color={'blue-deep'}
              className='mx-auto lg:hidden'
              style={{ marginTop: 0 }}
            />
          </S.SubHeaderWrapper>

          {showDropdown && (
            <S.SortDropdownStyled
              onChange={handleOptionChange}
              options={dropdownOptions || SORT_OPTIONS}
              placeholder={dropdownPlaceholder || SORT_PLACEHODLER}
              classNamePrefix={'you-fitness'}
              components={{
                IndicatorSeparator: () => null,
                DropdownIndicator: () => (
                  <span style={{ position: 'relative', right: 6 }}>
                    <SVGIcon name={IconEnums['icon-angle-down']} />
                  </span>
                )
              }}
            />
          )}
        </S.ContentWrapper>
        <UnderlineCustom
          color={'blue-deep'}
          className='mx-auto hidden lg:block'
          style={{ marginTop: 0 }}
        />
      </Container>
    </Section>
  )
}
SubHeader.ApiBlockToProps = (data: SubHeaderAPIProps) => {
  const { value, isFirstBlock } = data
  const props: SubHeaderProps = {
    subHeaderText: value.heading,
    showDropdown: false,
    showSeparator: true,
    isFirstBlock: isFirstBlock
  }
  return props
}

export default SubHeader
